<template>
  <div>
    <TB :tb_config="tb">
      <e-columns>
        <e-column
          field="id"
          headerText="ID"
          type="number"
          :isPrimaryKey="true"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          v-bind:field="'translations.' + this.$i18n.locale + '.name'"
          :headerText="$t('models.productproducer.name')"
          type="string"
          :template="nameTemplate"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="category_id"
          :headerText="$t('models.productproducer.category')"
          v-bind:foreignKeyValue="'translations.' + this.$i18n.locale + '.name'"
          foreignKeyField="id"
          :dataSource="categoryData"
          :template="categoryTemplate"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          v-bind:field="'translations.' + this.$i18n.locale + '.slug'"
          type="string"
          :headerText="$t('models.productproducer.slug')"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="is_active"
          :headerText="$t('models.productproducer.is_active')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="ts_created"
          :headerText="$t('common.table.created')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="ts_modified"
          :headerText="$t('common.table.last_modified')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
      </e-columns>
    </TB>
  </div>
</template>

<script>
import Vue from "vue";
import TB from "../../../common/TB";
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor from "../../../http/EJ2_adapter";
import { apiUrl } from "../../../http";

export default Vue.extend({
  name: "Products",
  components: {
    TB,
  },
  computed: {
    subPage() {
      return this.$route.params.pp_id;
    },
  },
  data() {
    return {
      tb: {
        data: new DataManager({
          url: apiUrl(`/api/producer/${this.$store.state.role.id}/products/`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        }),
        searchOptions: {
          fields: ["translations/it/name", "translations/it/slug", "id"],
        },
        childGrid: {
          dataSource: new DataManager({
            url: apiUrl(
              `/api/producer/${this.$store.state.role.id}/productlots/`
            ),
            adaptor: new DrfAdaptor(),
            crossDomain: true,
          }),
          queryString: "productproducer_id",
          load: function () {
            this.parentDetails.parentKeyFieldValue =
              this.parentDetails.parentRowData["id"];
          },
          columns: [
            {
              field: "id",
              headerText: "ID",
            },
            {
              field: "translations." + this.$i18n.locale + ".name",
              headerText: this.$t("models.lot.name"),
            },
            {
              field: "translations." + this.$i18n.locale + ".lot_description",
              headerText: this.$t("models.lot.lot_description"),
              visible: false,
            },
            {
              field: "is_active",
              headerText: this.$t("models.lot.is_active"),
              displayAsCheckBox: "true",
            },
            {
              field: "availability",
              headerText: this.$t("models.lot.availability"),
            },
            {
              field: "availability_type",
              headerText: this.$t("models.lot.availability_type"),
              visible: false,
            },
            {
              field: "producer_code",
              headerText: this.$t("models.lot.producer_code"),
            },
            {
              field: "expiry_date",
              headerText: this.$t("models.lot.expiry_date"),
            },
            { field: "price", headerText: this.$t("models.lot.price") },
            {
              field: "price_sugg",
              headerText: this.$t("models.lot.price_sugg"),
            },
            {
              field: "pw",
              headerText: this.$t("models.lot.pw"),
              displayAsCheckBox: "true",
            },
            {
              field: "wh_product",
              headerText: this.$t("models.lot.wh_product"),
              displayAsCheckBox: "true",
            },
            {
              field: "valid_from",
              headerText: this.$t("models.lot.valid_from"),
            },
            { field: "valid_to", headerText: this.$t("models.lot.valid_to") },
            {
              field: "ts_created",
              headerText: this.$t("common.table.created"),
              format: this.tsFormat,
              visible: false,
            },
            {
              field: "ts_modified",
              headerText: this.$t("common.table.last_modified"),
              format: this.tsFormat,
              visible: false,
            },
          ],
        },
      },
      categoryData: new DataManager({
        url: apiUrl(`/api/structure/category/`),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      }),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                    <router-link :to="link"><img class="table-img" :src="image" :alt="altImage"/></router-link>
                    <router-link :to="link">{{name}}</router-link>
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.image_thumb;
              },
              altImage: function () {
                return this.data.id;
              },
              name: function () {
                return this.data.translations[this.$i18n.locale].name;
              },
              link: function () {
                return (
                  "/role/" +
                  this.$store.state.role.id +
                  "/products/products/pp_" +
                  this.data.id
                );
              },
            },
          }),
        };
      },
      categoryTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="image">
                    <img class="table-img" :src="image" :alt="altImage"/>
                    {{name}}
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              image: function () {
                return this.data.foreignKeyData.image_thumb;
              },
              altImage: function () {
                return this.data.foreignKeyData.id;
              },
              name: function () {
                return this.data.foreignKeyData.translations[this.$i18n.locale]
                  .name;
              },
            },
          }),
        };
      },
    };
  },
});
</script>
